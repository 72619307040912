import { AnimatedColoredSection } from '@yoweb/ui/components/marketing/AnimatedColoredSection';
import {
  type AnimatedColoredSectionSliceResponse,
  requiredPrimaryFields,
} from './AnimatedColoredSectionSliceTypes';
import type { AnimatedColoredSectionProps } from '@yoweb/ui/components/marketing/AnimatedColoredSection';
import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';

type AnimatedColoredSectionSliceProps = {
  slice: AnimatedColoredSectionSliceResponse;
} & Pick<AnimatedColoredSectionProps, 'animationData'>;

const AnimatedColoredSectionSlice = ({
  slice,
  animationData,
}: AnimatedColoredSectionSliceProps) => {
  const { title, ctaHref, ctaLabel, bgColor, titleMaxWidth, animation, navId } = slice.primary;
  return (
    <div id={navId}>
      <AnimatedColoredSection
        title={title}
        titleMaxWidth={titleMaxWidth}
        ctaHref={ctaHref}
        ctaLabel={ctaLabel}
        bgColor={bgColor}
        animationData={animationData}
        bulletPoints={slice.items.map((item) => item.itemText)}
        animation={animation ?? 'juggling-a-lot'}
      />
    </div>
  );
};

export default withPrismicGuardian(AnimatedColoredSectionSlice, {
  primaryRequired: requiredPrimaryFields,
});
